import { Row, Col, Card, Form, Button, Container } from "react-bootstrap";
import React, { Component } from "react";
import teamwork from '../../assets/teamwork.png' //https://www.flaticon.com/authors/freepik
import flowchart from '../../assets/flow-chart.png' //https://www.flaticon.com/authors/freepik
import rank from '../../assets/rank.png' //https://www.flaticon.com/authors/freepik
import meeting from '../../assets/meeting.png' //https://www.flaticon.com/authors/freepik
import good from '../../assets/good.png' //https://www.flaticon.com/authors/freepik
import creation from '../../assets/creation.png' //https://www.flaticon.com/authors/freepik
import brainIcon from '../../assets/brain.svg' // https://www.flaticon.com/free-icon/brain_3035649?term=artificial%20intelligence&page=2&position=27

class WhyWork extends Component {
  state = {};
  render () {
    return (
      <section className="workwithus mt-5 mb-5">
        <Container>
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize" style={{ zIndex: "200" }}>Why work with us?</h2>
                <p className="d-none d-sm-block mt-4">

                </p>
                <p className="d-block d-sm-none mt-4">

                </p>
              </div>
            </div>
          </div>
          <Row>
            <Col lg="6" md="12" sm="12">
              <Card>
                <Card.Body className="row">
                  <Col lg="3" md="12">
                    <img class="card-icon" src={teamwork} alt="team management"></img>
                  </Col>
                  <Col lg="9" md="12">
                    {" "}
                    <Card.Title className="mb-4">User-centric Approach</Card.Title>
                    <Card.Text>
                      When working on startup MVPs, we focus on your end-users’
                      needs and expectations, not just the specification.
                      Continuous user testing and feedback implementation
                      guarantee a product that your users actually needs.
                    </Card.Text>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="6" md="12" sm="12" className="sp-margin">
              <Card>
                <Card.Body className="row">
                  <Col lg="3" md="12">
                    <img class="card-icon" src={flowchart} alt="team management"></img>
                  </Col>
                  <Col lg="9" md="12">
                    {" "}
                    <Card.Title>Agile Development</Card.Title>
                    <Card.Text>
                      User needs kept on changing depending on their environment,
                      An Agile team will ensure your product get to the hand of user
                      as soon as possible and fast enough to adapt to the changing world needs.
                    </Card.Text>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12" sm="12" className="mt-5">
              <Card>
                <Card.Body className="row">
                  <Col lg="3" md="12">
                    <img class="card-icon" src={brainIcon} alt="team management"></img>
                  </Col>
                  <Col lg="9" md="12">
                    {" "}
                    <Card.Title>AI & Big Data Specialization</Card.Title>
                    <Card.Text>
                      What differentiate us from others are our diverse team background,
                      developing an AI based solution? or a solution that require the consumption of
                      Data Analytics? what about the some out of this world ideas? Our team got it all!
                    </Card.Text>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="6" md="12" sm="12" className="mt-5">
              <Card>
                <Card.Body className="row">
                  <Col lg="3" md="12">
                    <img class="card-icon" src={creation} alt="team management"></img>
                  </Col>
                  <Col lg="9" md="12">
                    {" "}
                    <Card.Title>Business Thinking</Card.Title>
                    <Card.Text>
                      Our Solution Architect not only think how we can solve problem, we also think
                      the commercialization side of it. Ensuring your product match with the market at the right time and right user segment.
                    </Card.Text>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="12" sm="12" className="mt-5">
              <Card>
                <Card.Body className="row">
                  <Col lg="3" md="12">
                    <img class="card-icon" src={good} alt="team management"></img>
                  </Col>
                  <Col lg="9" md="12">
                    {" "}
                    <Card.Title>Startup Consultations</Card.Title>
                    <Card.Text>
                      Since we have experience building our own startup, we believe we could help you to get your ideas started
                      on the right track while removing the possibility of early mistake which could potentially cost you in the end.
                    </Card.Text>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="6" md="12" sm="12" className="mt-5">
              <Card>
                <Card.Body className="row">
                  <Col lg="3" md="12">
                    <img class="card-icon" src={meeting} alt="team management"></img>
                  </Col>
                  <Col lg="9" md="12">
                    {" "}
                    <Card.Title>Independent dedicated team</Card.Title>
                    <Card.Text>
                      Our team are allocated with sufficient time and resources to ensure they have the capability to
                      not only develop your ideas but also contribute in building your solution for the world!
                    </Card.Text>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default WhyWork;
