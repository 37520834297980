import React, { Component } from "react";
import CounterSection from "../counterSection/counter";
import axios from "axios";
import clazzy2 from "../../assets/clazzy2.png";
import kasikira from "../../assets/kasikira.jpg";
import lilyar from "../../assets/lilyar.png";
import { Controller, Scene } from "react-scrollmagic";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/appo-json/themeOneFeaturesSection";

class featureOne extends Component {
  state = {
    data: {},
    featuresData: [],
  };
  componentDidMount () {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          data: res.data,
          featuresData: res.data.featuresData,
        });
        // console.log(this.state.data)
      })
      .catch((err) => console.log(err));
  }
  render () {
    return (
      <section id="features" className="section features-area ptb_100 my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-6">
            {/* Section Heading */}
            <div className="section-heading text-center">
              <h2 className="text-capitalize" style={{ zIndex: "200" }}>
                Our Projects
              </h2>
              <p className="d-none d-sm-block mt-4"></p>
              <p className="d-block d-sm-none mt-4"></p>
            </div>
          </div>
        </div>
        <div className="shapes-container">
          <div className="shape bg-shape" />
        </div>
        <Controller>
          <Scene>
            <div className="container my-5 panel" data-aos="flip-up">
              <div className="row align-items-center feature1">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="features-text">
                    {/* Headings */}
                    <div className="headings d-flex align-items-center mb-4">
                      <h2 className="text-capitalize">Clazzy.my</h2>
                    </div>
                    <p className="my-3">
                      A Centralised Class Marketplace Platform, enabling anyone
                      with credibility and experiences to teach their passion
                      while earning money while doing so. Work Scope : Mobile &
                      Web Apps, Gig4Education Concept to product
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
                  {/* Featured Items */}
                  <div className="featured-items">
                    <ul>
                      <li>
                        <div className="single-features p-3">
                          <img src={clazzy2} alt="" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Scene>
          <Scene>
            <div className="container my-5 panel" data-aos="fade-right">
              <div className="row align-items-center feature2">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="features-text">
                    {/* Headings */}
                    <div className="headings d-flex align-items-center mb-4">
                      <h2 className="text-capitalize">KASIKIRA</h2>
                    </div>
                    <p className="my-3">
                      A Food Price AI Based Calculator, Enabling an automated
                      food price calculation via food image detection using
                      Inception Convolutional Neural Network CNN (Deep
                      Learning). Work Scope: AI Model Development & Engine
                      Deployment
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
                  {/* Featured Items */}
                  <div className="featured-items">
                    <ul>
                      <li>
                        <div className="single-features p-3">
                          <img src={kasikira} alt="" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Scene>
          <Scene>
            <div className="container my-5 panel" data-aos="fade-right">
              <div className="row align-items-center feature2">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="features-text">
                    {/* Headings */}
                    <div className="headings d-flex align-items-center mb-4">
                      <h2 className="text-capitalize">LilyPetuna AR</h2>
                    </div>
                    <p className="my-3">
                      An mobile apps that enable everyone to tryout LilyPetuna's
                      exclusive clothes using AR technology anytime &amp;
                      anywhere.
                      <br />
                      Work Scope: Augmented Reality &amp; 3D
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
                  {/* Featured Items */}
                  <div className="featured-items">
                    <ul>
                      <li>
                        <div className="single-features p-3">
                          <img src={lilyar} alt="" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Scene>
        </Controller>
      </section>
    );
  }
}

export default featureOne;
