import React, { Component } from 'react';

import ScrollupSection from '../components/scrollupSection/scrollUp';
import Header from '../components/headerSection/headerOne';
import WelcomeSection from '../components/welcomeSection/welcomeTwo';
import PromoSection from '../components/promoSection/promoTwo';
import AboutSection from '../components/aboutSection/aboutOne';
import WorkSection from '../components/workSection/workOne';
import FeatureSection from '../components/featureSection/featureOne';
import FeatureSection2 from '../components/featureSection/featureOne';
import FeatureSection3 from '../components/featureSection/featureOne';
import ScreenshotSection from '../components/screenshotSection/screenshot';
import PricingSection from '../components/pricingSection/pricingOne';
import ReviewSection from '../components/reviewSection/reviewOne';
import TeamSection from '../components/teamSection/teamOne';
import SubscribeSection from '../components/subscribeSection/subscribeOne';
import DownloadSection from '../components/downloadSection/downloadOne';
import BlogSection from '../components/blogSection/blogOne';
import ContactSection from '../components/contactSection/contact';
import FooterSection from '../components/footerSection/footer';
import WhyWork from '../components/whySection/whyWork';
import HowWork from '../components/howSection/howWork'
import productShowcase from '../components/productSection/productShowcase';

class Homepage extends Component {
    render () {
        return (
            <div className="riga">
                <ScrollupSection />
                <div className="all-area">
                    <Header imageData={"/img/qps-logo-new.png"} />
                    <WelcomeSection />
                    <WhyWork />
                    <HowWork />
                    <FeatureSection></FeatureSection>
                    <ReviewSection />
                    <TeamSection />
                    <SubscribeSection />
                    <ContactSection />
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default Homepage;