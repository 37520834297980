import React, { Component } from 'react';
import aqmal from '../../assets/team/IMG_5663.JPG';
import fitri from '../../assets/team/IMG_5667.JPG';
import syafiq from '../../assets/team/IMG_5706.JPG';
import syai from '../../assets/team/syaiwithback.jpg';
import ainol from '../../assets/team/IMG_5802.JPG'
import syahir from '../../assets/team/IMG_5799.JPG'

const initData = {
    heading: "Meet our Team Experts",
    headingText: "Building Software with Lean Principles",
    headingTextTwo: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati."
}

const teamData = [
    {
        image: aqmal,
        name: "Aqmal Adnan",
        position: "Founder",
        text: "Keeping the team cafeinated, healthy and wise. Lean Startup Enthusiast",
    },
    {
        image: fitri,
        name: "Fitri Sazali",
        position: "Head of Software Dev",
        text: "Code Tamer, magic maker, transform ideas into a master pieces",
    },
    {
        image: syahir,
        name: "Syahir Azmi",
        position: "Solution Architect",
        text: "The Idea Incubator, process ideas and transform them into workable manner"
    },
    {
        image: ainol,
        name: "Ainol Rozaimi",
        position: "Head of Solution Architect",
        text: "The Lean Startup Strategist. turning concept into working product",
    },
    {
        image: syafiq,
        name: "Syaafiq Asyraf",
        position: "Machine Intelligence & Data Molder",
        text: "Transform raw data into gem, AI Specialized Engineer",
    },
    {
        image: syai,
        name: "Syaiful Fikri",
        position: "UI / UX Architect",
        text: "User empathizer, the Ultimate Visual Architect",
    }
]

const socialData = [
    {
        iconClass: "fab fa-facebook-f"
    },
    {
        iconClass: "fab fa-twitter"
    },
    {
        iconClass: "fab fa-google-plus-g"
    },
    {
        iconClass: "fab fa-instagram"
    },
    {
        iconClass: "fab fa-linkedin-in"
    }
]

class teamOne extends Component {
    state = {
        initData: {},
        data: [],
        socialData: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: teamData,
            socialData: socialData
        })
    }
    render() {
        return (
            <section id="team" className="section team-area ptb_100">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-6">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-capitalize">{this.state.initData.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p>
                                {/* <p className="d-block d-sm-none mt-4">{this.state.initData.headingTextTwo}</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row-fluid">
                        {/* Team Slider */}
                        <div className="team-slider owl-carousel">
                            {/* Single Team */}
                            {this.state.data.map((item, idx) => {
                                return(
                                    <div key={`to_${idx}`} className="single-team text-center px-3 py-5 m-4">
                                        {/* Team Photo */}
                                        <div className="team-photo mx-auto">
                                            <img src={item.image} alt=""/>
                                        </div>
                                        {/* Team Content */}
                                        <div className="team-content mt-2 mb-1">
                                            <h3 className="team-name mt-3 mb-2">{item.name}</h3>
                                            <h5 className="team-post mb-2">{item.position}</h5>
                                            <p className="team-description">{item.text}</p>
                                            {/* Team Media */}
                                            {/* <div className="team-media mt-3">
                                                {this.state.socialData.map((item, idx) => {
                                                    return(
                                                        <a key={`sd_${idx}`} href="#">
                                                            <i className={item.iconClass} />
                                                        </a>
                                                    );
                                                })}
                                            </div> */}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default teamOne;