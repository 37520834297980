import React, { Component } from "react";
import axios from "axios";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/appo-json-2/themeOneContactSection";

class contactSection extends Component {
  state = {
    data: {},
  };
  componentDidMount () {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          data: res.data,
        });
        // console.log(this.state.data)
      })
      .catch((err) => console.log(err));
  }
  render () {
    return (
      <section id="contact" className="contact-area bg-gray ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize">
                  Schedule An Appointment
                </h2>
                <p className="d-none d-sm-block mt-4">

                </p>
                <p className="d-block d-sm-none mt-4">
                  Leave your contact details and our Solution Architect will contact you to schedule an appointment
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {/* Contact Box */}
              <div className="contact-box text-center">
                {/* Contact Form */}
                <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
                <iframe
                  class="airtable-embed airtable-dynamic-height"
                  src="https://airtable.com/embed/shrNfj8rzvsX4jXxW?backgroundColor=cyan"
                  frameborder="0"
                  onmousewheel=""
                  width="100%"
                  height="533"
                  style={{ background: "transparent", border: "1px solid #ccc" }}>
                </iframe>
                <p className="form-message" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default contactSection;
